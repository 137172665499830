<template>
  <v-container id="alerts" fluid tag="section">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-row justify="center">
      <v-dialog v-model="toggleFileUploadModel" max-width="600" persistent>
        <template>
          <v-card>
            <v-form
              ref="addReportForm"
              v-model="validateAddReport"
              lazy-validation
            >
              <v-card-title>
                <b style="font-size: 22px !important">Import Panelist Score</b>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row
                    justify="center"
                    style="text-align: center; font-size: 14px"
                  >
                    <b style="line-height: 31px"
                      >Upload CSV with one entry per row and fields in order:</b
                    >
                    PanelistID, FICO Score, VantageScore, CreditVision, Score
                    Month, Score Year
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        label="File input*"
                        accept=".csv"
                        outlined
                        dense
                        v-model="reportFile"
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row style="padding-left: 6%; margin-top: -42px">
                    <v-col>
                      <small>*indicates required field</small> <br />
                      <a
                        @mouseover="isHovering = true"
                        @mouseleave="isHovering = false"
                        :class="isHovering ? 'underline' : 'link-color'"
                        :href="sampleFile"
                        >Sample file</a
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!validateAddReport"
                  color="primary"
                  class="mr-4"
                  @click="addReport"
                >
                  Upload
                </v-btn>
                <v-btn color="default" @click="toggleFileUploadModel = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="errorModel" max-width="760" persistent>
        <template>
          <v-card>
            <v-form ref="addReportForm" lazy-validation>
              <v-card-title>
                <b style="font-size: 22px !important">Panelist Import Error</b>
              </v-card-title>
              <v-card-text>
                <v-container style="max-height: 570px; overflow-y: scroll">
                  <v-col cols="12">
                    <v-data-table
                      :headers="errorHeaders"
                      item-key="competi_id"
                      :items="errorList"
                      :loading="loading"
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                    >
                    </v-data-table>
                  </v-col>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeErrorMethods">
                  Close
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date1"
              label="Search by Month"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date1"
            type="month"
            no-title
            scrollable
            :max="max"
          >
            <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn> -->
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="panelist_ids"
          label="Enter Panelist Ids Comma Seperated"
        ></v-text-field>
      </v-col>
      <v-col cols="2" style="float: right">
        <v-btn small color="primary" class="mt-5" @click="getReport()">
          Filter
        </v-btn>
        <v-btn small color="default" @click="reset()" class="mt-5">
          Reset
        </v-btn>
      </v-col>
      <!-- <v-col cols="2"></v-col> -->
      <v-col cols="1">
        <div class="text-right">
          <v-btn
            small
            color="primary"
            dark
            class="mt-5"
            @click="openReportModel(false)"
            v-show="checkIfOperationExistForModule('import')"
          >
            Import
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          item-key="competi_id"
          :page.sync="page"
          :items="panalist_score"
          :options.sync="options"
          :sortBy.sync="sortby"
          :sort-desc.sync="sortDesc"
          :server-items-length="totalReports"
          :loading="loading"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item.competi_id="{ item }">
            {{ item.competi_id !== "NULL" ? item.competi_id : "" }}
          </template>
          <template v-slot:item.fico_score="{ item }">
            {{ item.fico_score !== "NULL" ? item.fico_score : "" }}
          </template>
          <template v-slot:item.vantage_score="{ item }">
            {{ item.vantage_score !== "NULL" ? item.vantage_score : "" }}
          </template>
          <template v-slot:item.credit_vision="{ item }">
            {{ item.credit_vision !== "NULL" ? item.credit_vision : "" }}
          </template>
          <template v-slot:item.score_date="{ item }">
            {{ item.score_date !== "NULL" ? item.score_date : "" }}
          </template>
          <template v-slot:item.created_date="{ item }">
            {{ item.created_date !== "NULL" ? item.created_date : "" }}
          </template>
        </v-data-table>

        <!-- <div></div> -->
        <div class="text-center pt-4 pb-4">
          <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import moment from "moment";

export default {
  name: "PanelistScore",

  components: {
    Loading,
    Pagination,
  },

  data: () => ({
    //for loading//
    loading: false,
    isLoading: true,
    fullPage: true,
    ///
    query: "",
    toggleFileUploadModel: false,
    isHovering: false,
    //form
    validateAddReport: false,
    reportDate: null,
    reportName: "",
    reportDesc: "",
    reportFile: null,
    editFid: "",
    editFileName: "",
    //
    //pagination code
    perpage: 50,
    totRecords: null,
    page: 1,
    isLoading: true,
    fullPage: true,
    showPerPage: false,

    ///
    //////table data starts here////
    selected: [],
    options: {},
    page: 1,
    panalist_score: [],
    pageCount: 0,
    totalReports: 0,
    // pagination: {
    //   rowsPerPage: 100,
    //   descending: false,
    //    sortable: false,
    //   page: 1
    // },
    editReportMode: false,
    fromDateMenu: false,
    fromDateVal: null,
    sampleFile:
      "https://files.competiscan.com/fileuploads/17238scoreUploads_sample.csv",
    max: moment(new Date()).format("YYYY-MM-DD"),
    date: new Date().toISOString().substr(0, 7),
    date1: "",
    sortby: "competi_id",
    sortDesc: false,
    sort: "asc",
    reset_flag: false,
    //  minDate: "2020-01-05",
    //  maxDate: "2019-08-30",
    headers: [
      {
        text: "Panelist ID",
        value: "competi_id",
      },
      { text: "FICO Score", value: "fico_score" },
      { text: "VantageScore", value: "vantage_score" },
      { text: "CreditVision", value: "credit_vision" },
      { text: "Score Date", value: "score_date" },
      { text: "Date", value: "created_date" },
    ],
    errorHeaders: [
      {
        text: "Row num",
        value: "row_num",
      },
      { text: "Panelist ID", value: "PanelistID" },
      { text: "FICO Score", value: "FICO Score" },
      { text: "Vantage Score", value: "VantageScore" },
      { text: "Credit Vision", value: "CreditVision" },
      { text: "Score Date", value: "Score Month" },
    ],
    availableHolidayDates: [],
    panelist_ids: "",
    holidayControl: 0,
    menu: false,
    errorList: [],
    errorModel: false,
    ////// table data ends here
  }),

  computed: {
    fromDate: function () {
      let date = this.date1;
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      return year + "-" + month + "-01";
    },
    toDate: function () {
      let date = moment(this.fromDate).add(1, "M").format("YYYY-MM-DD");
      let dateArr = date.split("-");
      let month = dateArr[1];
      let year = dateArr[0];
      //let days = this.getMonthDays(month,year)
      return year + "-" + month + "-01";
    },
    panelist_ids_arr: function () {
      if (this.panelist_ids == "") {
        return [];
      } else {
        let arr = this.panelist_ids.split(",");
        let new_arr = [];
        arr.forEach((id) => {
          new_arr.push(id.trim());
        });
        return new_arr;
      }
    },
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
  },
  watch: {
    page(obj) {
      this.page = obj;
      // this.getReport(obj)
    },
    options: function (o) {
      let sortby = o.sortBy[0];
      let direction = o.sortDesc[0];
      console.log("Options", o);
      if (!this.reset_flag) {
        if (sortby != undefined && direction != undefined) {
          this.sortby = sortby;
          if (o.page === 1) {
            this.page = 1;
          }
          if (direction) {
            this.sort = "desc";
          } else {
            this.sort = "asc";
          }
          console.log("Options.", this.sort, this.sortby);
          this.getReport();
        }
      }
      
    },
    toggleFileUploadModel(o) {
      let view = this;
      if (!o) {
        view.reportFile = null;
      }
    },
  },
  mounted() {
    //this.getTempData(this.pagination.page)
    const d = new Date();
    let tempmonth = d.getMonth() + 1;
    let month = 0;
    if (tempmonth < 10) {
      month = `0${tempmonth}`;
    } else {
      month = tempmonth;
    }
    let year = d.getFullYear();

    console.log(year + "-" + month);
    this.date1 = year + "-" + month;
  },

  methods: {
    //pagination methods
    getDataByPage(value) {
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    openReportModel(editMode, fid = 0) {
      this.toggleFileUploadModel = true;
      if (editMode) {
        this.editReportMode = true;
      } else {
        this.editReportMode = false;
        this.reportName = "";
        this.reportDesc = "";
        this.reportDate = null;
      }
      if (fid != 0) {
        this.getClientReport(fid);
      }
    },
    addReport() {
      let view = this;
      view.errorList = [];
      if (view.$refs.addReportForm.validate()) {
        let formData = new FormData();
        formData.append("file", this.reportFile);
        let manage_reports_url = view.$url("PANALISTUPLOAD");
        if (this.reportFile !== null) {
          if (this.reportFile.type === "text/csv") {
            view.isLoading = true;
            this.$fetch({
              requiresAuth: true,
              operation: "add",
              vueScope: view,
            })
              .post(manage_reports_url, formData, {
                headers: {
                  // 'Authorization': accestoken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                view.isLoading = false;
                view.toggleFileUploadModel = false;
                console.log("Response", response);

                view.errorList = response.data.data;
                // if (view.errorList.length > 0) {
                //   view.errorModel = true;
                // }
                if (response.data.message === "File uploaded successfully.") {
                  view.$swal.fire(
                    {
                      icon: "success",
                      title: "Successsss",
                      text: response.data.message,
                    },
                  ).then((res) => {
                    if(res.value){
                      view.getReport();
                    }
                  });
                } else {
                  view.$swal
                    .fire({
                      icon: "success",
                      title: "Success",
                      text: response.data.message,
                      showCancelButton: true,
                      confirmButtonText: "Show Error table",
                      cancelButtonText: "Ok",
                      showCloseButton: true,
                      showLoaderOnConfirm: true,
                      allowOutsideClick: false,
                    })
                    .then((result) => {
                      if (result.value) {
                        view.errorModel = true;
                      } else {
                        view.getReport();
                      }
                    });
                }

                // view.reportFile = null;
                // view.getTempData(view.page);
              })
              .catch((error) => {
                
                view.isLoading = false;
                // view.reportFile = null;
                view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response.data.message,
                });
              });
          } else {
            view.isLoading = false;
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Only csv format allowed!",
            });
          }
        } else {
          view.isLoading = false;
          view.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select csv file!",
          });
        }
      }
    },
    getEventsList() {
      var eventsDates = document.querySelectorAll(
        ".v-date-picker-table .v-btn"
      );
      for (var i = 0; i < eventsDates.length; i++) {
        let nextSibling = eventsDates[i].children;
        if (nextSibling[1] != undefined) {
          nextSibling[0].style.color = "#ff0000";
        }
      }
      this.holidayControl = 1;
    },
    reset() {
      console.log("REst")
      let view = this;
      view.reset_flag = true;
      view.page = 1;
      view.date1 = new Date().toISOString().substr(0, 7);
      view.panelist_ids = "";
      view.sortby = "competi_id";
      view.sortDesc = false;
      view.sort = "asc";
      view.getReport();
    },
    //Get Data
    getReport(page = "", query = "", categ = "") {
      let check = this;
      // let producer_panelist_report_url = check.$url("PANALISTREPORT");
      // Change url acc to env files
      let panalist_score_url = check.$url("PANALISTREPORT");
      check.isLoading = true;
      let data = {
        from_date: this.fromDate,
        to_date: this.toDate,
        start_index: this.startIndex,
        end_index: this.endIndex,
        panelist_list: this.panelist_ids_arr,
        sorted_by: this.sort,
        sorted_column_name: this.sortby,
      };
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: check })
        .post(panalist_score_url, data)
        .then((res) => {
          check.isLoading = false;
          if (res.data.report.length) {
            check.panalist_score = res.data.report;
            check.totalReports = res.data.report_length;
            check.totRecords = res.data.report_length;
            if (this.totRecords <= this.perpage) {
              this.page = 1;
            }
            this.reset_flag = false;
          } else {
            check.panalist_score = [];
            check.totalReports = res.data.report_length;
            check.totRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          check.panalist_score = [];
          check.totalReports = 0;
          check.totRecords = null;
          console.error(error);
        });
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    closeErrorMethods() {
      let view = this;
      view.errorModel = false;
      view.getReport();
      // location.reload();
    },
  },
};
</script>

<style>
.link-color {
  font-size: 13px;
  text-decoration: none;
}
.underline {
  color: black !important;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
}
</style>
